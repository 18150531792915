<template>
	<a-space direction="vertical">
		<a-card title="查询条件">
			<a-form-model @submit.prevent="onSearch" layout="horizontal" v-bind="{ labelCol: { span: 6 }, wrapperCol: { span: 16 } }">
				<a-row>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="ID"><a-input v-model="query.uid" placeholder="请输入用户ID"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="手机号码/昵称"><a-input v-model="query.mobile" placeholder="请输入手机号码/昵称"></a-input></a-form-model-item>
					</a-col>

					<a-col :md="8" :sm="24">
						<a-form-model-item label="上级手机号码"><a-input v-model="query.superior" placeholder="请输入上级手机号码"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="业绩时间">
							<a-month-picker placeholder="请选择业绩时间" format="YYYY-MM" :value="query.month" @change="onMonthChange" style="width:100%" />
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="结算金额"><a-input v-model="query.money" placeholder="请输入结算金额"></a-input></a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item label="状态">
							<a-select v-model="query.status" placeholder="请选择状态" style="width:100%">
								<a-select-option value="">全部</a-select-option>
								<a-select-option value="0">未结算</a-select-option>
								<a-select-option value="1">待结算</a-select-option>
								<a-select-option value="2">已结算</a-select-option>
								<a-select-option value="4">上级抵扣</a-select-option>
								<a-select-option value="5">无人抵扣</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :md="8" :sm="24"></a-col>
					<a-col :md="8" :sm="24">
						<a-form-model-item :wrapper-col="{ span: 16, offset: 6 }">
							<a-space>
								<a-button type="primary" html-type="submit">查询</a-button>
								<a-button @click="onReset">重置</a-button>
							</a-space>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-card>

		<a-card title="市场服务费列表">
			<a-space slot="extra"><a-button type="primary" @click="onExport">导出</a-button></a-space>

			<div class="statistics">
				<span>订单总数：{{ lists.total }}</span>
				<span>订单用户：{{ lists.countUser }}</span>
				<span>
					结算总金额：{{ lists.countMoney }}
					<a-tooltip placement="top">
						<template slot="title">
							<span>用户总金额+用户保证金</span>
						</template>
						<a-icon type="question-circle" />
					</a-tooltip>
				</span>
				<span>
					用户总金额：{{ lists.userMoney }}
					<a-tooltip placement="top">
						<template slot="title">
							<span>扣除用户保证金后，结算至用户可提现余额的金额</span>
						</template>
						<a-icon type="question-circle" />
					</a-tooltip>
				</span>
				<span>
					用户保证金：{{ lists.bond }}
					<a-tooltip placement="top">
						<template slot="title">
							<span>从结算金额扣除60%至用户保证金账户，扣满为止</span>
						</template>
						<a-icon type="question-circle" />
					</a-tooltip>
				</span>
				<span>
					待结算金额：{{ lists.expectMoney }}
					<a-tooltip placement="top">
						<template slot="title">
							<span>当月不满足结算条件，暂不结算金额</span>
						</template>
						<a-icon type="question-circle" />
					</a-tooltip>
				</span>
			</div>
			<a-table
				:data-source="lists.data"
				:pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }"
				@change="onPageChange"
				:scroll="{ x: 'max-content', scrollToFirstRowOnChange: true }"
			>
				<a-table-column key="id" title="订单ID" data-index="id" />
				<a-table-column key="userId" title="用户信息" data-index="userId">
					<template slot-scope="text, record">
						<div v-if="!!text">
							{{ text }}
							<br />
							{{ record.nickname }} （{{ record.mobile }}
							<a href="javascript:;" v-clipboard:copy="record.mobile" v-clipboard:success="onCopySuccess"><a-icon type="copy" /></a>
							）
						</div>
					</template>
				</a-table-column>
				<a-table-column key="createMonth" title="业绩时间" data-index="createMonth" />
				<a-table-column key="teamMoney" title="业绩金额" data-index="teamMoney" />
				<a-table-column key="totalMoney" title="结算金额" data-index="totalMoney" />
				<a-table-column key="userMoney" title="用户金额" data-index="userMoney" />
				<a-table-column key="bond" title="保证金" data-index="bond" />
				<a-table-column key="status" title="状态" data-index="status">
					<template slot-scope="status, record">
						<a-tag color="green" v-if="record.status == 2">{{ record.statusStr }}</a-tag>
						<a-tag color="blue" v-else-if="record.status > 0">{{ record.statusStr }}</a-tag>
						<a-tag v-else>{{ record.statusStr }}</a-tag>
					</template>
				</a-table-column>
				<!-- <a-table-column key="ctime" title="创建时间" data-index="ctime" /> -->
				<a-table-column key="action" title="操作" fixed="right">
					<template slot-scope="text, record">
						<a-space><a-button size="small" @click="onView(record)">详情</a-button></a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>

		<a-modal title="业绩订单列表" v-model="modalOrder.visible" :centered="true" :footer="null" :bodyStyle="{ padding: '10px' }">
			<div class="nodata" style="padding: 30px 0;" v-if="modalOrder.lists.total == 0"><a-empty></a-empty></div>
			<div class="order-lists" v-else>
				<div style="max-height: 78vh; overflow-y: auto; ">
					<div class="order-item" v-for="item in modalOrder.lists.data">
						<div class="header">
							<div class="sn">订单：{{ item.id }}</div>
							<div>{{ item.statusStr }}({{ item.bizTypeStr }})</div>
						</div>
						<div class="content">
							<img class="cover" :src="item.image" />
							<div class="title">
								<div class="item">用户姓名：{{ item.nickname }} ({{ item.mobile }})</div>
								<div class="stitle">
									<div>订单数量： {{ item.num }}</div>
									<div v-show="item.giveNum && item.giveNum > 0">赠送数量： {{ item.giveNum }}</div>
								</div>
							</div>
						</div>
						<div class="footer">
							<div class="date">{{ item.ctime }}</div>
							<div>
								<div class="total">¥{{ item.money }}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination"><a-pagination :current="modalOrder.lists.current_page" :total="modalOrder.lists.total" show-less-items @change="onOrderPageChange" /></div>
			</div>
		</a-modal>
	</a-space>
</template>

<script>
import moment from 'moment';
import { ROLES } from '@/utils/constants.js';
const formatter = 'YYYY-MM-DD';

const DEFAULT_QUERY = {
	page: 1,
	size: 10,
	uid: undefined,
	mobile: undefined,
	superior: undefined,
	status: undefined,
	money: undefined,
	month: undefined
};

export default {
	data() {
		return {
			query: Object.assign({}, DEFAULT_QUERY),
			lists: {
				data: [],
				current_page: 1,
				per_page: 10,
				total: 0
			},
			modalOrder: {
				visible: false,
				query: {},
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				}
			}
		};
	},
	mounted() {
		this.getServiceCharges();
	},
	methods: {
		async getServiceCharges() {
			const response = await this.$api.get('/service_charge_list', {
				params: this.query
			});
			if (response && response.code == 200) {
				Object.assign(this.lists, response.data);
			}
		},
		async onPageChange(pagination) {
			this.query.page = pagination.current;
			this.query.size = pagination.pageSize;
			this.getServiceCharges();
		},
		async onSearch() {
			this.query.page = 1;
			this.getServiceCharges();
		},
		async onReset() {
			this.query = Object.assign({}, DEFAULT_QUERY);
			this.getServiceCharges();
		},
		async onMonthChange(o, dateStr) {
			this.query.month = dateStr;
		},
		async onExport() {
			this.$store.dispatch('setLoading', true);
			const response = await this.$api.get('/service_charge_export', {
				params: { ...this.query, exportType: 1 },
				responseType: 'arraybuffer'
			});
		},
		async onView(record) {
			this.modalOrder.query = {
				uid: record.userId,
				month: String(record.createMonth).replace(/(^\d{4})/, '$1-'),
				page: 1
			};
			this.modalOrder.lists = {
				data: [],
				current_page: 1,
				per_page: 10,
				total: 0
			};
			this.getServiceOrders();
			this.modalOrder.visible = true;
		},
		async getServiceOrders() {
			const response = await this.$api.get('/service_detail', {
				params: this.modalOrder.query
			});
			if (response && response.code == 200) {
				this.modalOrder.lists = Object.assign(this.modalOrder.lists, response.data);
			}
		},
		onOrderPageChange(page) {
			this.modalOrder.query.page = page;
			this.getServiceOrders();
		}
	}
};
</script>

<style lang="less">
.order-item {
	padding: 14px 20px 20px 14px;
	border-bottom: #eaeaea 1px solid;
	.header {
		display: flex;
		align-items: center;
	}

	.content {
		margin-top: 10px;
		display: flex;
	}

	.footer {
		margin-top: 10px;
		display: flex;
		align-items: center;
	}

	.cover {
		display: block;
		width: 100px;
		height: 100px;
		border-radius: 4px;
	}

	.sn {
		flex: 1;
		color: #838383;
		font-size: 13px;
	}

	.status {
		font-size: 14px;
		font-weight: 500;
		color: #fc873a;
	}

	.title {
		margin-left: 10px;
		color: #333333;
		font-size: 14px;
		flex: 1;
		line-height: 22px;
		min-width: 0;

		.item {
			height: 24px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			.label {
				float: left;
				width: 60px;
				text-align: justify;
				&::after {
					content: '';
					width: 100%;
					display: inline-block;
					height: 0;
				}
			}
		}

		.pname {
			line-height: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	.stitle {
		color: #555555;
		margin-top: 10px;
		font-size: 13px;
		// display: flex;
		//  div{
		// 	 flex: 1;
		//  }

		& + .stitle {
			margin-top: 4px;
		}
	}

	.meta {
		// width: 158px;
		text-align: right;
		color: #666666;
		padding-top: 16px;
	}

	.price {
		font-size: 14px;
	}

	.quantity {
		margin-top: 16px;
		font-size: 13px;
	}

	.date {
		flex: 1;
		color: #999999;
		font-size: 13px;
	}

	.total {
		font-size: 14px;
		font-weight: 500;
		color: #333333;

		&::before {
			font-size: 12px;
			font-weight: 400;
			content: '订单金额：';
		}
	}
	.vtotal {
		// margin-left: 20px;
		font-size: 14px;
		font-weight: 500;
		color: #333333;

		&::before {
			font-size: 12px;
			font-weight: 400;
			content: '业绩金额：';
		}
	}
}
.pagination {
	padding: 10px 0;
	text-align: right;
}
</style>
